import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <section class="bg-dark">
        <div class="container-lg">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-10 order-md-0 pt-6 pt-md-10 pb-12 pb-md-13 text-center text-md-left">
              <h6 class="font-family-sans-serif text-warning mb-5">
                <Link to="/blog" title="Blog" className="text-warning">
                  🎉&nbsp; BLOG
                </Link>
              </h6>

              <h1 class="display-3 text-white mb-4">{title}</h1>

              <p class="font-size-lg text-white mb-0">{description}</p>
            </div>
          </div>
        </div>
      </section>

      <div class="position-relative">
        <div class="shape shape-fluid-x shape-top text-white">
          <div class="shape-img pb-8 pb-md-10">
            <svg viewBox="0 0 100 50" preserveAspectRatio="none">
              <path d="M0 25h25L75 0h25v50H0z" fill="currentColor" />
            </svg>
          </div>
        </div>
      </div>

      <section class="py-9 py-md-11">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
              <PostContent content={content} />
              <hr class="my-9" />

              <footer class="d-flex align-items-center">
                <div class="avatar avatar-sm">
                  <img
                    class="avatar-img rounded-circle"
                    src="/img/avatars/rcatom.png"
                    alt="Reno Collective"
                  />
                </div>

                <div class="ml-4">
                  <h4 class="font-family-sans-serif mb-0">Reno Collective</h4>

                  <p class="small text-muted mt-n1 mb-0">Published {date}</p>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.frontmatter.description} />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://renocollective.com/" />
        <meta property="twitter:title" content={post.frontmatter.title} />
        <meta
          property="twitter:description"
          content={post.frontmatter.description}
        />
      </Helmet>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
